export enum MonitorRolesEnum {
  DEFAULT_ROLES_ADMIN = 'default-roles-admin',
  FINANCE_PAYOUT_STRIPE = 'finance-payout-stripe',
  FLEET_ADMIN = 'fleet-admin',
  FLEET_BASIC = 'fleet-basic',
  FLEET_DELEGATION = 'fleet-delegation',
  FLEET_DOCUMENTOS_ADMIN = 'fleet-documentos-admin',
  FLEET_INVENTORY = 'fleet-inventory',
  FLEET_INVENTORY_MANAGER = 'fleet-inventory-manager',
  FLEET_IOT_CHECKLIST = 'rental-fleet-iot-checklist',
  FLEET_IOT_CREATION = 'rental-fleet-iot-creation',
  FLEET_IOT_DELETION = 'rental-fleet-iot-deletion',
  FLEET_IOT_EDITION = 'rental-fleet-iot-edition',
  FLEET_IOT_UNLINK = 'rental-fleet-iot-unlink',
  FLEET_IOT_LINK = 'rental-fleet-iot-link',
  FLEET_INVENTORY_ADMIN = 'fleet-inventory-admin',
  FLEET_JOURNEY_EDIT = 'fleet-journey-edit',
  FLEET_JOURNEY_READER = 'fleet-journey-reader',
  FLEET_LINK_IOT = 'fleet-link-iot',
  FLEET_LOCK_IMMEDIATE = 'rental-fleet-lock-immediate', // Acesso super restrito (pode causar acidentes)
  FLEET_LOCK_UNLOCK = 'rental-fleet-lock-unlock',
  FLEET_MAKE_CHECKLIST = 'fleet-make-checklist',
  FLEET_MONITOR_SERVICE_VIEW = 'fleet-monitor-service-view',
  FLEET_MONITOP_VIEW_ALL = 'fleet-monitor-view-all',
  FLEET_PITSTOP = 'fleet-pitstop',
  FLEET_RESOLVE_ALERTS = 'rental-fleet-resolve-alerts',
  FLEET_STREET_OPERATION = 'fleet-street-operation',
  FLEET_STREET_SUPPORT = 'fleet-street-support',
  FLEET_SUPPORT_CANCELLATION = 'fleet-support-cancellation',
  FLEET_SEIZURE_FINISH = 'fleet-seizure-finish',
  FLEET_TRACK_VEHICLES = 'rental-fleet-track-vehicles',
  FLEET_THIRD_ADMIN = 'fleet-third-administrative',
  FLEET_USER_FACTORY = 'fleet-user-factory',
  FLEET_VEHICLE_CREATION = 'rental-fleet-vehicle-creation',
  FLEET_VEHICLE_DELETION = 'rental-fleet-vehicle-deletion',
  FLEET_VEHICLE_EDITION = 'rental-fleet-vehicle-edition',
  FLEAT_VEHICLE_RESTRICTION = 'rental-fleet-vehicle-restriction',
  FLEET_AUTOMATIC_DISTRIBUTION = 'rental-fleet-automatic-collection',
  IOT_ADMIN = 'iot-admin',
  MAINTENANCE_IOT_BASIC = 'maintenance-iot-basic',
  MANAGE_ACCOUNT = 'manage-account',
  MANAGE_ACCOUNT_LINKS = 'manage-account-links',
  OFFLINE_ACCESS = 'offline_access',
  PEOPLE_ADMIN = 'people-admin',
  UMA_AUTHORIZATION = 'uma_authorization',
  VIEW_PROFILE = 'view-profile',
  RENTAL_FLEET_MOTTUFIELD_INTERNAL = 'rental-fleet-mottufield-internal',
  RENTAL_FLEET_MOTTUFIELD_THIRD = 'rental-fleet-mottufield-third',
  RENTAL_FLEET_MOTTUFIELD_THIRD_ADM = 'rental-fleet-mottufield-third-adm',
  RENTAL_FLEET_MOTTUFIELD_ADM = 'rental-fleet-mottufield-adm',
  RENTAL_FLEET_MOTTUFIELD_SALES = 'rental-fleet-monitor-salesrepresentative',
}
