import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ProtocoloIot } from "../models/Checklist/protocolo.model";

@Injectable()
export class ChecklistService {
  private baseUrl = environment.baseUrlMonitor;
  private operationBaseUrl = environment.baseUrlOperation;

  constructor(private http: HttpClient) {}

  testaRecurso(veiculoId, recurso: string) {
    return this.http.get(
      this.baseUrl +
        "api/v1/veiculo/" +
        veiculoId +
        "/verificarpreparacao/" +
        recurso +
        "?forcar=true"
    );
  }
  

  aprovarTest(veiculoId) {
    return this.http.post(
      this.baseUrl + "api/v1/veiculo/" + veiculoId + "/prepararaluguel",
      {}
    );
  }

  aprovarChecklist(veiculoId, bypass: boolean) {
    return this.http.post(
      this.baseUrl +
        "api/v1/veiculo/" +
        veiculoId +
        "/FinishChecklist?bypass=" +
        bypass,
      {}
    );
  }

  getLocalizacao(veiculoId) {
    return this.http.get(
      this.baseUrl +
        "api/v1/veiculo/" +
        veiculoId +
        "/localizacao?checkGrafana=true"
    );
  }

  newChecklist(veiculoId) {
    return this.http.post(
      this.baseUrl + "api/v1/veiculo/" + veiculoId + "/NewChecklist",
      {}
    );
  }

  getChecklistServico(servicoTipo: number) {
    return this.http.get(
      this.baseUrl + "api/v1/ChecklistServico/" + servicoTipo
    );
  }
  getVeiculoRestricao(veiculoId: number) {
    return this.http.get(
      this.baseUrl + "api/v1/VeiculoRestricao/" + veiculoId + "/obter/CHECKLIST"
    );
  }

  alterarConfigIot(model: ProtocoloIot) {
    return this.http.post(this.baseUrl + "api/v1/iot/alterarConfig", model);
  }

  getVeiculoModelo() {
    return this.http.get(
      this.operationBaseUrl + "api/v2/VeiculoModelo"
    );
  }
}
