import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RedirectRoutingModule } from './redirect-routing.module';
import { RedirectComponent } from './redirect.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [RedirectComponent],
  imports: [
    CommonModule,
    RedirectRoutingModule,
    MatProgressBarModule,
  ],
})
export class RedirectModule {}
