import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseRequestResult } from '../models/BaseRequestResult.model';
import { UserSuporte, VeiculoSuporte } from '../models/ServicoVeiculoSuporte.model';
import { AlterarSituacaoModel, AlterarSituacaoVeiculoResponse, FiliaisResponse, situacaoRegraModel } from '../models/Unidades.model';
import { SuporteTerceiroVeiculo } from '../models/SuporteTerceiroVeiculo.model';
import { MonitoramentoMovimentacaoVeiculoModel } from '../models/MonitoramentoMovimentacaoVeiculoModel';
import { BateriaLocalizacao } from '../models/BateriaLocalizacao.model';
import { BaseMaisProximaModel, GrupoLugarLocal } from '../models/GrupoLugar.model';
import { VeiculoLocalizacaoMapa } from '../models/VeiculoLocalizacaoMapa.model';
import { CadastroVeiculoTerceiro } from '../models/terceiros/cadastro-veiculo-terceiro.model';
import { UsuarioLocalizacao } from '../models/Usuario/UsuarioLocalizacao.model';
import { viewAccessAdmin } from '../models/mottuAdminCS/viewAccess.model';
import { BranchManagementResult } from '../models/branch-management.model';
import { EnderecoVirtualCargaModelV2 } from 'src/app/components/pages/monitor-module/models/EnderecoVirtualV2.model';
import { IVehicleBasicInfo } from 'src/app/components/pages/home-template/model/home-menu';
import { Vehicle, VehicleResponse } from '../../components/pages/monitor-map/models/list.model';
import { TipoPesquisaInativoEnum } from '../enum/tipoPesquisaEnum';
import { TenantData } from '../../components/pages/monitor-map/models/tenant.model';
import { OrderHistory, SupportVehicles } from '../../components/pages/monitor-map/models/vehicles-detail.model';
import { UserAppTelemetryModel } from 'src/app/components/pages/monitor-module/models/UserAppTelemetryModel';

@Injectable({
  providedIn: 'root',
})
export class MonitorService {
  private baseUrl = environment.baseUrl;
  private baseUrlMonitor = environment.baseUrlMonitor;
  private baseUrlMottueBackend = environment.baseUrlMottueBackend;
  private baseUrlIotLock = environment.baseUrlIotLock;
  private baseUrlCSv2 = environment.baseUrlCustomerSuccessV2;

  constructor(private http: HttpClient) {}

  getVeiculosMapa(value: number | string): Observable<BaseRequestResult<Array<VehicleResponse>>> {
    const searchByPlate = typeof(value) === 'string';
    const param = searchByPlate ? 'placa' : 'veiculoId';
    return this.http.get<BaseRequestResult<Array<VehicleResponse>>>(`${this.baseUrlMonitor}api/v1/veiculo/mapa?${param}=${value}`);
  }

  getVeiculos(placa) {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/veiculo/mapa?placa=' + placa
    );
  }

  getVeiculo(veiculoId) {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/veiculo/mapa?veiculoId=' + veiculoId
    );
  }

  atualizaVeiculos() {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/veiculo/localizacoes?padrao=nomeado'
    );
  }

  buscarBaterias(veiculoId: number) {
    return this.http.get<BaseRequestResult<BateriaLocalizacao[]>>(
      this.baseUrlMottueBackend +
        `api/v1/Bateria/ObterPorVeiculoId/${veiculoId}/localizacao`
    );
  }

  buscaVeiculoLocalizacao(veiculoId: number) {
    return this.http.get(
      this.baseUrlMonitor +
        `api/v1/Veiculo/${veiculoId}/localizacao?padrao=monitor&checkGrafana=true`
    );
  }

  previaAlertas(veiculoId) {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/veiculo/' + veiculoId + '/ultimoAlerta'
    );
  }

  getAlertas(filtro: string) {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/veiculoalerta/' + filtro
    );
  }

  getAlertasLista(status, searchParams) {
    return this.http.get(
      this.baseUrlMonitor + `api/v1/veiculoalerta/${status}?${searchParams}`
    );
  }

  getAlertasDetalhe(veiculoAlertaId) {
    return this.http.get(
      this.baseUrlMonitor + `api/v1/VeiculoAlerta/detalhes/${veiculoAlertaId}`
    );
  }

  bloquearAlerta(veiculodId) {
    return this.http.post(
      this.baseUrlMonitor + 'api/v1/veiculo/' + veiculodId + '/bloquear',
      {}
    );
  }

  alertaDescartar(veiculoAlertaId, comentario, enviarParaCS) {
    return this.http.post(
      this.baseUrlMonitor +
        `api/v1/veiculoalerta/descartar/${veiculoAlertaId}?comentario=${comentario}&enviarCS=${enviarParaCS}`,
      {}
    );
  }
  alertaOcorrencia(veiculoAlertaId, comentario, enviarParaCS) {
    return this.http.post(
      this.baseUrlMonitor +
        `api/v1/veiculoalerta/ocorrencia/${veiculoAlertaId}?comentario=${comentario}&enviarCS=${enviarParaCS}`,
      {}
    );
  }
  sendSms(type, veiculoId, imei?) {
    const dataImei = imei ? `&imei=${imei}` : '';
    return this.http.post(
      this.baseUrlMonitor +
        `api/v1/iot/send-sms/?type=${type}&veiculoId=${veiculoId}${dataImei}`,
      {}
    );
  }
  recolherVeiculo(payload) {
    return this.http.post(this.baseUrl + 'cs/ticket', payload);
  }

  bloquearVeiculo(veiculoSelecionadoId) {
    return this.http.post<BaseRequestResult<string>>(
      this.baseUrlMonitor + 'api/v1/OrdemBloqueioDesbloqueio',
      veiculoSelecionadoId
    );
  }
  desbloquearVeiculo(veiculoSelecionadoId) {
    return this.http.post(
      this.baseUrlMonitor +
        'api/v1/veiculo/' +
        veiculoSelecionadoId +
        '/desbloquear',
      {}
    );
  }

  bloquearVeiculoSms(veiculoSelecionadoId){
    return this.http.post(
      this.baseUrlMonitor + 'api/v1/OrdemBloqueioDesbloqueio/SMS/?type=Bloqueio&veiculoId=' +
      veiculoSelecionadoId,
      {}
    );
  }

  desbloquearVeiculoSms(veiculoSelecionadoId){
    return this.http.post(
      this.baseUrlMonitor + 'api/v1/OrdemBloqueioDesbloqueio/SMS/?type=Desbloqueio&veiculoId=' +
      veiculoSelecionadoId,
      {}
    );
  }

  desbloquearTemporarioVeiculo(blockPayload) {
    return this.http.post(
      this.baseUrlMonitor + 'api/v1/OrdemBloqueioDesbloqueio/temporario',
      blockPayload
    );
  }
  getVeiculosSuport() {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/VeiculoSuporte/disponiveis'
    );
  }
  getVeiculosSuportFilial() {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/VeiculoSuporte/disponiveis/filial'
    );
  }

  getVeiculosReservaLocalizacoes() {
    return this.http.get(
      this.baseUrlMonitor +
        'api/v1/veiculo/localizacoes?padrao=nomeado&reserva=true'
    );
  }

  getVehicleBasicInfo(filtro = ''): Observable<BaseRequestResult<Vehicle[]>> {
    return this.http.get<BaseRequestResult<Vehicle[]>>(
      this.baseUrlMonitor + 'api/v1/veiculo/resumos?filtro=' + filtro
    );
  }

  getVehicleBasicInfoV2(filtro, tipo): Observable<BaseRequestResult<IVehicleBasicInfo[]>> {
    return this.http.get<BaseRequestResult<IVehicleBasicInfo[]>>(
      this.baseUrlMonitor + 'api/v1/veiculo/resumos-v2?filtro=' + filtro + '&tipoFiltroPesquisa=' + tipo
    );
  }

  getVehicleBasicInfoInativo(filtro: string, tipo: TipoPesquisaInativoEnum): Observable<BaseRequestResult<any>> {
    return this.http.get<BaseRequestResult<any>>(
      this.baseUrlMonitor + 'api/v1/Usuario/Inativos?filtro=' + filtro + '&tipo=' + tipo
    );
  }

  getVeiculosHistoricoLocalizacao(
    veiculodId,
    dataInicio,
    dataFim,
    filtroVelocidade = false,
    locatarioId = 0,
    scale = 0
  ): Observable<BaseRequestResult<Array<any>>> {
    return this.http.get<BaseRequestResult<Array<any>>>(
      this.baseUrlMonitor +
        'api/v1/Telemetria?veiculoId=' +
        veiculodId +
        '&dataInicio=' +
        dataInicio +
        '&dataFim=' +
        dataFim +
        '&filtroVelocidade=' +
        filtroVelocidade +
        '&locatarioId=' +
        locatarioId +
        '&scale=' +
        scale
    );
  }

  getLocalizacaoVeiculo(veiculoId, openCellEnabled = false) {
    return this.http.get<BaseRequestResult<UsuarioLocalizacao>>(
      this.baseUrlMonitor +
        'api/v1/veiculo/' +
        veiculoId +
        '/localizacao?padrao=nomeado&openCellEnabled=' +
        openCellEnabled
    );
  }
  getLocalizacao(veiculoId) {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/veiculo/' + veiculoId + '/localizacao'
    );
  }

  getUsuarioLocalizacao(usuarioId): Observable<BaseRequestResult<UsuarioLocalizacao>> {
    return this.http.get<BaseRequestResult<UsuarioLocalizacao>>(
      this.baseUrlMonitor + 'api/v1/Usuario/' + usuarioId + '/localizacao'
    );
  }

  getVehicleLocation(veiculoId: number) {
    return this.http.get<BaseRequestResult<VeiculoLocalizacaoMapa>>(
      `${this.baseUrlMonitor}api/v1/veiculo/${veiculoId}/localizacao-veiculo`
    );
  }

  getLocalizacaoUsuario(usuarioId): Observable<BaseRequestResult<string>> {
    return this.http.get<BaseRequestResult<string>>(
      this.baseUrlMonitor +
        'api/v1/Usuario/BuscarLocalizacaoUsuario?usuarioId=' + usuarioId
    );
  }

  getEnderecosVirtuaisPorVeiculoId(veiculoId) {
    return this.http.get<BaseRequestResult<any>>(
      this.baseUrlMonitor + 'api/v1/EnderecoVirtual/veiculo/' + veiculoId
    );
  }
  getEnderecosVirtuaisPorUsuarioId(usuarioId) {
    return this.http.get<BaseRequestResult<any>>(
      this.baseUrlMonitor + 'api/v1/EnderecoVirtual/usuario/' + usuarioId
    );
  }

  getEnderecosVirtuaisPorUsuarioIdV2(usuarioId) {
    return this.http.get<BaseRequestResult<EnderecoVirtualCargaModelV2[]>>(
      this.baseUrlMonitor + 'api/v1/EnderecoVirtual/v2/usuario/' + usuarioId
    );
  }

  setVisualizacaoUltimoPing(veiculoId: number) {
    return this.http.get<BaseRequestResult<boolean>>(
      this.baseUrlMonitor + `api/v1/veiculo/${veiculoId}/buscarUltimoPing`
    );
  }
  setVisualizacaoLocatarioDetails(veiculoId: number): Observable<BaseRequestResult<TenantData>> {
    return this.http.get<BaseRequestResult<TenantData>>(
      this.baseUrlMonitor +
        `api/v1/Veiculo/locatario-details/${veiculoId}/new-session`
    );
  }

  setVisualizacaoLocatarioDetailsByUserId(UsuarioId: number) {
    return this.http.get<BaseRequestResult<any>>(
      this.baseUrlMonitor +
        `api/v1/Veiculo/locatario-details/new-session/${UsuarioId}`
    );
  }

  GetVisualizacaoLocatarioDetails(veiculoId: number) {
    return this.http.get<BaseRequestResult<any>>(
      this.baseUrlMonitor + `api/v1/Veiculo/locatario-details/${veiculoId}`
    );
  }
  getVeiculosSuporteLocalizacoes(): Observable<BaseRequestResult<SupportVehicles[]>> {
    return this.http.get<BaseRequestResult<SupportVehicles[]>>(
      this.baseUrlMonitor + 'api/v1/VeiculoSuporte/localizacao'
    );
  }
  removerVeiculoSuporte(veiculoId: number) {
    return this.http.delete<BaseRequestResult<boolean>>(
      this.baseUrlMonitor + `api/v1/VeiculoSuporte/${veiculoId}`
    );
  }

  deleteVeiculoSuporte(veiculoPlaca: string) {
    return this.http.delete<BaseRequestResult<boolean>>(
      this.baseUrlMonitor + `api/v1/VeiculoSuporte/time-rua/${veiculoPlaca}`
    );
  }
  novoVeiculoSuporte(veiculoSuporte: VeiculoSuporte) {
    return this.http.post<BaseRequestResult<VeiculoSuporte>>(
      this.baseUrlMonitor + 'api/v1/VeiculoSuporte/time-rua',
      veiculoSuporte
    );
  }
  novoVeiculoSuporteTerceiro(veiculoSuporte: CadastroVeiculoTerceiro) {
    return this.http.post<BaseRequestResult<VeiculoSuporte>>(
      this.baseUrlMonitor + 'api/v1/Terceiro/veiculo/app',
      veiculoSuporte
    );
  }

  adicionarSuporteTerceiroVeiculo(
    suporteTerceiroVeiculo: SuporteTerceiroVeiculo
  ) {
    return this.http.post<BaseRequestResult<boolean>>(
      this.baseUrlMonitor + 'api/v1/VeiculoSuporte/terceiro',
      suporteTerceiroVeiculo
    );
  }
  buscarSuporteTerceiroVeiculo(usuarioId: number) {
    return this.http.get<BaseRequestResult<SuporteTerceiroVeiculo[]>>(
      this.baseUrlMonitor + `api/v1/VeiculoSuporte/terceiro/${usuarioId}`
    );
  }
  buscarBaseMaisProxima(veiculoSuporteId: number) {
    return this.http.get<BaseRequestResult<GrupoLugarLocal>>(
      this.baseUrlMonitor +
        `api/v1/VeiculoSuporte/${veiculoSuporteId}/base-mais-proxima`
    );
  }

  buscarBaseMaisProximaV2(veiculoSuporteId: number, veiculoId: number) {
    return this.http.get<BaseRequestResult<BaseMaisProximaModel[]>>(
      this.baseUrlMonitor +
        `api/v1/VeiculoSuporte/${veiculoSuporteId}/v2/base-mais-proxima/${veiculoId}`
    );
  }

  setBloqueioImediato(veiculoId, ordemBloqueio) {
    return this.http.post(
      this.baseUrlMonitor +
        'api/v1/ordembloqueiodesbloqueio/perigo/bloqueio?veiculoId=' +
        veiculoId +
        '&bloqueio=' +
        ordemBloqueio,
      {}
    );
  }
  GetBloqueioStatus(imei) {
    return this.http.get(
      this.baseUrlMonitor +
        'api/v1/ordembloqueiodesbloqueio/situacao/iot/' +
        imei
    );
  }

  validarRastreamento(veiculoId): Observable<BaseRequestResult<any>> {
    return this.http.get<BaseRequestResult<any>>(
      this.baseUrlMonitor + 'api/v1/veiculo/validarRastreamento/' + veiculoId
    );
  }

  getUltimaOrdem(veiculoId) {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/OrdemBloqueioDesbloqueio/ultimaByVeiculoId/' + veiculoId
    );
  }

  getHistoricoBlock(imei): Observable<BaseRequestResult<Array<OrderHistory>>> {
    return this.http.get<BaseRequestResult<Array<OrderHistory>>>(
      this.baseUrlMonitor + 'api/v1/OrdemBloqueioDesbloqueio/historico/' + imei
    );
  }

  getHistoricoByUserId(UserId): Observable<BaseRequestResult<Array<OrderHistory>>> {
    return this.http.get<BaseRequestResult<Array<OrderHistory>>>(
      this.baseUrlMonitor + 'api/v1/OrdemBloqueioDesbloqueio/historicoByUsuarioId/' + UserId
    );
  }


  cancelaOrdem(imei) {
    return this.http.delete(this.baseUrlIotLock + 'lock/' + imei);
  }

  vincularUserVeiculo(userId, veiculoSuporteId) {
    return this.http.post(
      this.baseUrlMonitor +
        'api/v1/UsuarioSuporte/' +
        userId +
        '/veiculo/' +
        veiculoSuporteId +
        '/ingressar',
      {}
    );
  }
  desvincularUserVeiculo(
    usuarioId: number
  ): Observable<BaseRequestResult<UserSuporte>> {
    return this.http.post<BaseRequestResult<UserSuporte>>(
      this.baseUrlMonitor +
        `api/v1/UsuarioSuporte/${usuarioId}/veiculo/abandonar`,
      {}
    );
  }

  desvincularUserVeiculoByAdmin(usuarioId: number) {
    return this.http.post<BaseRequestResult<UserSuporte>>(
      `${this.baseUrlMonitor}api/v1/UsuarioSuporte/${usuarioId}/veiculo/abandonar-admin`,
      {}
    );
  }

  getLocatarioInfo(locatarioId) {
    return this.http.get(
      this.baseUrlMonitor + 'api/v1/Locatario/' + locatarioId
    );
  }
  getTelemetria(data) {
    return this.http.get(
      this.baseUrlMonitor +
        'api/v1/Telemetria/local' +
        '?latitude=' +
        data.latitude +
        '&longitude=' +
        data.longitude +
        '&raio=' +
        data.raio +
        '&dataInicio=' +
        data.dataInicio +
        '&dataFim=' +
        data.dataFim
    );
  }
  getTelemetriaToExport(data) {
    return this.http.get(
      this.baseUrlMonitor +
        'api/v1/Telemetria/local/export' +
        '?latitude=' +
        data.latitude +
        '&longitude=' +
        data.longitude +
        '&raio=' +
        data.raio +
        '&dataInicio=' +
        data.dataInicio +
        '&dataFim=' +
        data.dataFim
    );
  }
  getVeiculoModelos() {
    return this.http.get(this.baseUrlMonitor + 'api/v1/veiculomodelo');
  }

  getBasesLocacao() {
    return this.http.get<BaseRequestResult<FiliaisResponse[]>>(
      this.baseUrlMonitor + 'api/v1/BaseLocation/ObterBasesLocacao'
    );
  }

  getSituacaoRegraPorSituacaoAnterior(situacaoAnterior) {
    return this.http.get<BaseRequestResult<situacaoRegraModel>>(
      this.baseUrlMonitor +
        'api/v1/VeiculoSituacaoRegras/BuscarRegrasPorSituacaoAnterior?descricaoSituacaoAnterior=' +
        situacaoAnterior
    );
  }

  alterarSituacao(payload: AlterarSituacaoModel) {
    return this.http.post<BaseRequestResult<AlterarSituacaoVeiculoResponse>>(
      this.baseUrlMonitor + 'api/v1/Veiculo/Situacao',
      payload
    );
  }

  alterarLugar(payload) {
    return this.http.patch<BaseRequestResult<any>>(
      this.baseUrlMonitor + 'api/v1/Veiculo/AlterarLugarId',
      payload
    );
  }

  criarNotificacaoMovimentacao(payload: MonitoramentoMovimentacaoVeiculoModel) {
    return this.http.post<
      BaseRequestResult<MonitoramentoMovimentacaoVeiculoModel>
    >(this.baseUrlMonitor + 'api/v1/MonitoramentoMovimentacaoVeiculo', payload);
  }

  verificarNotificacaoMovimentacao(placa: string, usuarioId: number) {
    return this.http.get<BaseRequestResult<boolean>>(
      this.baseUrlMonitor +
        'api/v1/MonitoramentoMovimentacaoVeiculo/existenotificacao/' +
        usuarioId +
        '/' +
        placa
    );
  }

  interromperNotificacaoMovimentacao(placa: string, usuarioId: number) {
    return this.http.delete<BaseRequestResult<boolean>>(
      this.baseUrlMonitor +
        'api/v1/MonitoramentoMovimentacaoVeiculo/interrompernotificacao/' +
        usuarioId +
        '/' +
        placa
    );
  }
  buscarVeiculosSuportePorId(ids) {
    return this.http.get<BaseRequestResult<VeiculoSuporte[]>>(
      this.baseUrlMonitor +
        `api/v1/VeiculoSuporte/obter-veiculos-suporte?${ids}`
    );
  }

  validaAcessoVeiculoUsuario(usuarioId: number) {
    return this.http.get<BranchManagementResult<viewAccessAdmin>>(`${this.baseUrlCSv2}/api/v1/MottuAdmin/users/${usuarioId}/view-access`);
  }

  validaAcessoVeiculoPlaca(plate: string) {
    return this.http.get<BranchManagementResult<viewAccessAdmin>>(`${this.baseUrlCSv2}/api/v1/MottuAdmin/vehicle/${plate}/view-access`);
  }

  getVeiculoById(veiculoId: number) {
    return this.http.get<BaseRequestResult<any>>(
      this.baseUrlMonitor + `api/v1/Veiculo/${veiculoId}`
    );
  }

  getAplicativoUsuario(
    dataInicio,
    dataFim,
    locatarioId = 0,
    scale = 0
  ): Observable<BaseRequestResult<Array<any>>> {
    return this.http.get<BaseRequestResult<Array<UserAppTelemetryModel>>>(
      this.baseUrlMonitor +
        'api/v1/Telemetria/aplicativo-usuario?dataInicio=' +
        dataInicio +
        '&dataFim=' +
        dataFim +
        '&usuarioId=' +
        locatarioId +
        '&scale=' +
        scale
    );
  }
}
