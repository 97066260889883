import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ServicosService {
  constructor() {}

  private servicoData = new Subject<any>();
  private showNotificacoes = new BehaviorSubject<boolean>(false);

  emitDataServico(data: ServicoSendData){
    this.servicoData.next(data);
  }
  listenDataServico(){
    return this.servicoData.asObservable();
  }

  emitShowNotificacoes(show: boolean){
    this.showNotificacoes.next(show);
  }
  listenShowNotificacoes(){
    return this.showNotificacoes.asObservable();
  }

  completeData(){
    this.servicoData.next(null);
    this.servicoData.complete();
  }
}
export interface ServicoSendData {
  key: string;
  value: any;
  servico?: any | null;
}
