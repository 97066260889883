import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CensorPipe } from './shared/pipes/censor.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from '@mottu-ops/keycloak-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppRoutingModule } from './app-routing.module';
import { MonitorDialog } from './shared/dialogs/monitor-dialog/monitor-dialog';
import { ResolverDialog } from './shared/dialogs/resolver-dialog/resolver-dialog';

import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';
import { GANTT_GLOBAL_CONFIG, NgxGanttModule } from '@worktile/gantt';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AtomModule } from './components/atom/atom.module';
import { AdminGuard } from './components/auth/admin.guard';
import { AuthGuard } from './components/auth/auth.guard';
import { HttpErrorInterceptor } from './components/auth/error.interceptor';
import { GlobalErrorHandler } from './components/auth/handler/global-error.handler';
import { MonitorProfileGuard } from './components/auth/monitor.profile.guard';
import { RenavamSupportGuard } from './components/auth/renavam-suporte.guard';
import { RoleGuard } from './components/auth/role.guard';
import { LoginLayoutComponent } from './components/layouts/login-layout.component';
import { MaterialModule } from './material.module';
import { AddComentarioDialog } from './shared/dialogs/add-comentario-dialog/add-comentario-dialog';
import { ChangeProtocolDialog } from './shared/dialogs/changeprotocol-dialog/changeprotocol-dialog';
import { ChecklistDialog } from './shared/dialogs/checklist-dialog/checklist-dialog';
import { ConfirmarDataDialog } from './shared/dialogs/confirmar-data-dialog/confirmar-data-dialog';
import { ConfirmarDialog } from './shared/dialogs/confirmar-dialog/confirmar-dialog';
import { DeleteDataDialog } from './shared/dialogs/delete-data-dialog/delete-data-dialog';
import { DeleteDialog } from './shared/dialogs/delete-dialog/delete-dialog';
import { GaleryDialog } from './shared/dialogs/galery-dialog/galery-dialog';
import { GenerateCrrDialog } from './shared/dialogs/generate-crr-dialog/generate-crr-dialog';
import { ImageDialog } from './shared/dialogs/image-dialog/image-dialog';
import { InputDialog } from './shared/dialogs/input-dialog/input-dialog';
import { NotificarMovimentacaoDialog } from './shared/dialogs/notificar-movimentacao-dialog/notificar-movimentacao-dialog';
import { ReprocessarDialog } from './shared/dialogs/reprocessar-dialog/reprocessar-dialog';
import { CustomLoaderInterceptor } from './shared/interceptors/loader-interceptor';
import { AutoCompleteGoogleService } from './shared/services/autocomplete-google.service';
import { ChecklistService } from './shared/services/checklist.service';
import { InternalAuthService } from './shared/services/internalauth.service';
import { NewsletterService } from './shared/services/newsletter.service';
import { OneSignalService } from './shared/services/oneSignal.service';

registerLocaleData(ptBr);

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/sso/silent-check-sso.html',
        silentCheckSsoFallback: !isSafari(),
        checkLoginIframe: !isSafari(),
      },
      enableBearerInterceptor: true,
    });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    MonitorDialog,
    ImageDialog,
    ResolverDialog,
    DeleteDialog,
    DeleteDataDialog,
    ConfirmarDialog,
    ConfirmarDataDialog,
    InputDialog,
    ChecklistDialog,
    GaleryDialog,
    AddComentarioDialog,
    NotificarMovimentacaoDialog,
    GenerateCrrDialog,
    ReprocessarDialog,
    ChangeProtocolDialog
  ],
  imports: [
    NgxGanttModule,
    BrowserModule,
    AppRoutingModule,
    InfiniteScrollModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    AtomModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production && !isSafari(),
      registrationStrategy: 'registerWhenStable:5000',
    }),
    MatIconModule,
    HttpClientModule,
    KeycloakAngularModule,
  ],
  providers: [
    InternalAuthService,
    NewsletterService,
    AuthGuard,
    MonitorProfileGuard,
    RoleGuard,
    AdminGuard,
    RenavamSupportGuard,
    AutoCompleteGoogleService,
    ChecklistService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    OneSignalService,
    {provide: LOCALE_ID, useValue: 'pt-PT'},
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JwtInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomLoaderInterceptor,
      multi: true
    },
    {
      provide: GANTT_GLOBAL_CONFIG,
      useValue: {
        dateFormat: {
          day: 'dd/MM/yyyy',
          month: 'MM/yyyy',
          week: 'dd/MM/yyyy',
        }
      }
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
